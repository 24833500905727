let cart = new Cart();

let shop = function () {
    shop.container = $('.cart');
    shop.total = $('.cartTable__total');
    shop.buttons = $('.cartTable__calc__buttons');
    shop.headCart = $('.head__cart');
    shop.deliveryInputs = $('.cart .forma__field_delivery input');
    shop.fixCart = $('.addProdPopup');
    shop.winProduct = $('.addProdPopup__item');
    shop.closeBut = $('.addProdPopup__close, #backCatalog');

    // shop.deliveryPrice  = 0;

    shop.currency = {
        //basic: $('meta[name="currency-basic"]').attr('content'),
        name: $('meta[name="currency-name"]').attr('content'),
    }
    shop.srcCart = $('meta[name="cart:url"]').attr('content');

    shop.pageOptionCart = $('body').hasClass('option-cart') ? 1 : 0;

    shop.request();
    shop.buy();
    shop.number();
    shop.delProdInCart();
    shop.closeWindow();
}

shop.deliveryChange = function () {
    /** Доставка */
    let orderForm = new CheckForms($('#orderForm'), '.button_submit');
    let addressBlock = shop.container.find('.forma__field_address');

    shop.deliveryInputs.on('change', function() {
        $(this).val() == 'none-delivery' ? addressBlock.hide().find('input').removeClass('requiredField errorField emptyField') : addressBlock.show().find('input').addClass('requiredField');
        orderForm.checkEmpty();
    });
}

shop.addProdInCart = function (data) {
    if (!shop.headCart.hasClass('vis')) shop.headCart.addClass('vis');
    shop.headCart.find('span').text(data.totalQuantity);
}

shop.construct = function (data) {
    for (let key in data.cart) {
        let product = new Product(data.cart[key]);
        cart.addProduct(product);
    }
    shop.updateCartTable(data);
    shop.delivType = data.delivery;
    //shop.validateDelivery();
}

shop.constructBoxCart = function (data) {
    for (let key in data.cart) {
        let product = new Product(data.cart[key]);
        cart.addProduct(product);
        shop.addProdInCart(data);
    }
}

shop.request = function () {
    cart.pull(shop.srcCart, function (data) {
        if (shop.pageOptionCart) {
            shop.construct(data);
        } else {
            shop.constructBoxCart(data);
        }
    });
}

shop.delProdInCart = function () {
    shop.container.on('click', '.icon-cross', function() {
        let row = $(this).parents('.cartItem');
        let id = row.data('id');
        row.remove();
        cart.delProduct(id);
        if (cart.products.length == 0) location.reload();
        cart.push(shop.srcCart, cart.products, function (data) {
            //Cart.calcSumTotal();
            shop.total.each(function() {
                $(this).text(data.formatTotal + ' ' + shop.currency.name);
            });
        });
    });
}

shop.updateCartTable = function (data) {
    /*shop.total.each(function() {
        $(this).text(numberFormat(data.total, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    });*/

    shop.total.each(function() {
        $(this).text(numberFormat(data.total + ' ' + shop.currency.name, {decimals: 0, thousands_sep: ' '}));
    });

    // shop.$totalQuantity.text(data.totalQuantity);

    // var sum = Number(data.total) + Number(shop.deliveryPrice);
    // shop.$orderTotal.text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
}

shop.number = function () {
    let timeout;
    let allowedRegex = /^[0-9]+$/;
    let allowedCharRegex = /[0-9]/;

    function setTotal (data, row) {
        /*shop.total.each(function() {
            $(this).text(numberFormat(data.total + ' ' + shop.currency.name, {decimals: 0, thousands_sep: ' '}));
        });*/
        for (let i in data.cart) {
            if (data.cart[i].id == row.data('id')) {
                let sum = cart.calc(data.cart[i].price, row.find('[name = quantity]').val());

                shop.container.find('[data-id = '+row.data('id')+']').each(function() {
                    $(this).find('.cartTable__sum').text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
                });

                break;
            }
        }
        //shop.validateDelivery();
        shop.updateCartTable(data);
    }

    function changeCount (row, productId, val) {
        // ищем в обьекте корзины нужный товар по id
        for (let key in cart.products) {
            if (cart.products[key].id == productId) {
                // обновляем ему кол-во
                cart.products[key].quantity = parseInt(val);
            }
        }

        timeout = setTimeout(function() {
            cart.push(shop.srcCart, cart.products, function (data) {
                setTotal(data, row);
                //shop.validateDelivery();
            });
        }, 300);
    }

    $('body').on('keyup', '[name = quantity]', function() {
        clearTimeout(timeout);

        if ($(this).val() === '') $(this).val('1');

        // нашли id изменяемого товара
        if (shop.pageOptionCart) {
            let row = $(this).closest('.cartItem');
            let id = $(this).parents('.cartItem').data('id');
            changeCount(row, id, $(this).val());
        }
    }).on('paste', '[name = quantity]', function(e) {
        // handle only copy paste here
        // get clipboard text
        let newValue = e.originalEvent.clipboardData.getData('Text');

        // if new value is incorrect then stop event
        if (!allowedRegex.test(newValue)) {
            e.stopPropagation();
            return false;
        }
        if ($(this).val() != '' && $(this).val() != '0' && shop.pageOptionCart) changeCount($(this).parents('.cartItem'), $(this).parents('.cartItem').data('id'), $(this).val());
        //cloneThis.calcSum($(this).parents('tr'));
    }).on('keypress', '[name = quantity]', function(e) {
        // check input character
        return allowedCharRegex.test(e.key);
    }).on('focusout mouseout', '[name = quantity]', function() {
        if ($(this).val() == '') $(this).val(shop.products[id].price);
        if (shop.pageOptionCart) {
            // let id = shop.pageOptionCart ? $(this).parents('.cartItem').data('id') : $(this).parent().find('.tocart').data('id');
            let id = $(this).parents('.cartItem').data('id');
            changeCount($(this).parents('.cartItem'), id, $(this).val());
        }
    }).on('click', '.plus, .minus', function() {
        clearTimeout(timeout);
        let inp = $(this).parent().find('[name = quantity]');
        let quantity = parseInt(inp.val());

        if ($(this).hasClass('plus')) quantity ++;
        else if (quantity > 1) quantity --;
        inp.val(quantity);

        // нашли id изменяемого товара
        if (shop.pageOptionCart) {
            let row = $(this).closest('.cartItem');
            let id = $(this).parents('.cartItem').data('id');
            changeCount(row, id, quantity);
        }
    });
}

shop.construct = function (data) {
    for (var key in data.cart) {
        var product = new Product(data.cart[key]);
        cart.addProduct(product);
    }
    shop.updateCartTable(data);
    shop.delivType = data.delivery;
    //shop.validateDelivery();
}

shop.constructBoxCart = function (data) {
    for (let key in data.cart) {
        let product = new Product(data.cart[key]);
        cart.addProduct(product);
        shop.addProdInCart(data);
    }
    //shop.cartBox.find('.total .num').text(data.formatTotal + ' ' + shop.currency.name);
}

shop.request = function () {
    cart.pull(shop.srcCart, function (data) {
        shop.pageOptionCart ? shop.construct(data) : shop.constructBoxCart(data);
    });
}

shop.closeWindow = function () {
    let win = shop.winProduct.parent();

    shop.closeBut.on('click', function(event) {
        shop.winProduct.empty();
        shop.fixCart.removeClass('vis');
        event.preventDefault();
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(win) && !win.find($target).length && !$target.is(shop.closeBut) && !shop.closeBut.find($target).length && shop.fixCart.hasClass('vis')) shop.closeBut.trigger('click');
    });
}

shop.constructWindow = function(item) {
    if ($('body').find('.getWindow').length) $('.mfp-close').trigger('click');

    shop.fixCart.addClass('vis');

    shop.winProduct.html('<img src='+item.src+' alt="" /><div class="fixCartBlock__item__title">'+item.name+'</div><div class="fixCartBlock__item__quantity">Количество: '+item.quantity+'</div><div class="fixCartBlock__item__price">'+item.price+' ₽</div>');
}

shop.buy = function () {
    //shop.cartBox = $('#mm').find('.mm__cart').next();
    $(document).on('click', '.catalog__item__btns__incart, .tocart', function(e) {
        e.preventDefault();
        let item = $(this);
        
        let product = new Product({
            id: item.data('id'),
            name: item.data('name'),
            price: item.data('price'),
            formatPrice: item.data('format-price'),
            link: item.data('link'),
            color: item.data('color'),
            box: item.data('box'),
            // size: item.data('size'),
            src: item.data('src'),
            quantity: item.data('quantity'),
            article: item.data('article'),
        });

        cart.addProduct(product, product.quantity);

        cart.push(shop.srcCart, cart.products, function (data) {
            shop.addProdInCart(data);
            item.hasClass('tocart') ? item.parent().hide().next().css('display', 'inline-block') : item.hide();
            $('[data-id = '+ item.data('id')+']').hide();
            shop.constructWindow(product);
        });
    });
}

function numberFormat (number, cfg) {
	function obj_merge(obj_first, obj_second){
		var obj_return = {};
		for (key in obj_first){
			if (typeof obj_second[key] !== 'undefined') obj_return[key] = obj_second[key];
			else obj_return[key] = obj_first[key];
		}
		return obj_return;
	}
	function thousands_sep(num, sep){
		if (num.length <= 3) return num;
		var count = num.length; var num_parser = ''; var count_digits = 0;
		for (var p = (count - 1); p >= 0; p--){
			var num_digit = num.substr(p, 1);
			if (count_digits % 3 == 0 && count_digits != 0 && !isNaN(parseFloat(num_digit))) num_parser = sep + num_parser;
			num_parser = num_digit + num_parser;
			count_digits++;
		}
		return num_parser;
	}
	if (typeof number !== 'number'){
		number = parseFloat(number);
		if (isNaN(number)) return false;
	}
	var cfg_default = {before: '', after: '', decimals: 2, dec_point: '.', thousands_sep: ','};
	if (cfg && typeof cfg === 'object'){ cfg = obj_merge(cfg_default, cfg); } else cfg = cfg_default;
	number = number.toFixed(cfg.decimals);
	if (number.indexOf('.') != -1){
		var number_arr = number.split('.');
		var number = thousands_sep(number_arr[0], cfg.thousands_sep) + cfg.dec_point + number_arr[1];
	} else var number = thousands_sep(number, cfg.thousands_sep);
	return cfg.before + number + cfg.after;
}